<template>
  <div class="evidence">
    <div style="padding-top: 10px;">佐证材料</div>
    <el-divider></el-divider>
    <div v-show="main_page">
      <el-form :model="formInline" label-width="80" :inline="true" class="demo-form-inline">
        <el-form-item>
          <el-select v-model="formInline.parentLabel" size="small" placeholder="请选择一级标签" @change="handleParentLabel">
            <el-option v-for="item in formInline.parentOptions" :key="item.id" :label="item.title" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="sonShow">
          <el-select v-model="formInline.sonLabel" size="small" placeholder="请选择子标签" @change="handleSonLabel">
            <el-option v-for="item in formInline.sonOptions" :key="item.id" :label="item.title" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" icon="el-icon-search" @click="handleSearch">查询</el-button>
          <el-button type="primary" size="small" icon="el-icon-folder-add" @click="handleMaterial">添加材料</el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="upload" @click="dialogFormVisible = true">+ 添加材料</div> -->
      <div v-if="cardList.length > 0" style="padding-bottom: 50px">
        <div class="card-content">
          <div class="card" v-for="(item, index) in cardList" :key="index">
            <div>
              <img v-show="item.suffix == 'jpg' || item.suffix == 'png'" :src="item.file" alt="" @click="previewPDF(item)" />
              <img v-show="item.suffix == 'docx'" src="../../../../assets/pc/images/member/DOC.png" alt="" @click="previewPDF(item)" />
              <img v-show="item.suffix == 'xlsx'" src="../../../../assets/pc/images/member/XLS.png" alt="" @click="previewPDF(item)" />
              <img v-show="item.suffix == 'pdf'" src="../../../../assets/pc/images/member/PDF.png" alt="" @click="previewPDF(item)" />
              <img v-show="item.suffix == 'ptt'" src="../../../../assets/pc/images/member/PPT.png" alt="" @click="previewPDF(item)" />
            </div>
            <div style="width: 170px;">
              <el-tooltip :content="item.remarkName" placement="top" :disabled="item.remarkName.length < 12">
                <div class="title">{{ item.remarkName }}</div>
              </el-tooltip>
              <div class="text">{{ item.originalName }}</div>
              <div class="text">有效期：{{ item.effectiveDate }}</div>
            </div>
            <div>
              <el-dropdown trigger="click">
                <span class="el-dropdown-link">
                  <img src="../../../../assets/pc/images/member/more.png" alt="" style="width: 16px; height: 10px" />
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="download(item)">下载</el-dropdown-item>
                  <el-dropdown-item @click.native="edit(item)">编辑</el-dropdown-item>
                  <el-dropdown-item @click.native="remove(item)">删除</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
        <el-pagination background layout="prev, pager, next" :total="total" :current-page.sync="currentPage" :page-size="pageSize" @current-change="handleCurrentChange" v-show="total > 0" style="text-align: center">
        </el-pagination>
      </div>

      <div v-else class="none-data">
        <img src="@/assets/pc/images/personal/none.png" alt="" />
        <span style="font-size: 14px; color: #b0b0b0">暂无内容</span>
      </div>
    </div>
    <div v-show="!main_page">
      <div>
        <el-button @click="handleReturn">{{ return_back }}</el-button>
      </div>
      <div class="content_edit">
        <div class="edit_img">
          <img v-show="form.suffix == 'jpg' || form.suffix == 'png'" :src="form.file" alt="" />
          <img v-show="form.suffix == 'docx'" src="../../../../assets/pc/images/member/DOC.png" alt="" />
          <img v-show="form.suffix == 'xlsx'" src="../../../../assets/pc/images/member/XLS.png" alt="" />
          <img v-show="form.suffix == 'pdf'" src="../../../../assets/pc/images/member/PDF.png" alt="" />
        </div>
        <div class="edit_form">
          <div class="remarkName">
            <div class="form_title">材料名称</div>
            <div>
              <div class="remarkName_title" v-show="!form.show_input">
                {{ form.remarkName }}
              </div>
              <div class="remarkName_title" v-show="form.show_input">
                <el-input v-model="form.remarkName" placeholder="请输入备注名" style="width: 460px;"></el-input>
              </div>
              <div class="modify" @click="handleUpdate" v-show="form.update_name == '修改'">
                {{ form.update_name }}
              </div>
              <div style="display: flex;">
                <div class="modify" @click="handleSure" v-show="form.update_name == '确认'">
                  {{ form.update_name }}
                </div>
                <div class="modify" @click="handleCancle" v-show="form.update_name == '确认'" style="margin-left: 10px;">
                  取消
                </div>
              </div>
            </div>
          </div>
          <div class="originalName">
            <div class="form_title">原文件名</div>
            <div class="remarkName_title">{{ form.originalName }}</div>
          </div>
          <div class="addTime">
            <div class="form_title">上传日期</div>
            <div>{{ form.addTime }}</div>
          </div>
          <div>
            <div class="form_title">选择分类</div>
            <div class="form_select">
              <el-cascader v-model="form.value" :options="form.options" @change="handleChange"></el-cascader>
            </div>
          </div>
          <div>
            <div class="form_title">证件有效期</div>
            <div class="form_radio_group">
              <el-radio-group v-model="form.radio">
                <el-radio :label="1" style="margin-bottom: 20px">无期限</el-radio>
                <el-radio :label="0">设置有效期<el-date-picker v-model="form.date" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="margin-left: 20px">
                  </el-date-picker></el-radio>
              </el-radio-group>
              <div></div>
            </div>
          </div>
          <div class="form_tips">系统将在证件过期前30天进行提示</div>
          <div class="form_sub" @click="handleSave">保存修改</div>
        </div>
      </div>
    </div>
    <el-dialog title="添加佐证材料" :visible.sync="dialogFormVisible" width="35%" append-to-body>
      <el-form ref="dialogForm" :model="dialogForm" label-width="100px">
        <el-form-item label="材料名称：">
          <el-input v-model="dialogForm.name" style="width: 90%"></el-input>
        </el-form-item>
        <el-form-item label="选择分类：" v-show="!formInline.parentId || !formInline.childId">
          <el-cascader style="width: 90%" v-model="dialogForm.value" :options="dialogForm.options" @change="catgory" v-if="dialogForm.options.length">
          </el-cascader>
        </el-form-item>
        <el-form-item label="选择文件：">
          <el-upload class="upload-demo" :action="uploadUrl" :on-success="handleSuccess" :on-exceed="handleExceed" :before-upload="beforeAvatarUpload" accept=".jpg,.png,.pdf,.doc,.xlsx,.xls,.docx,.csv" multiple :limit="1" :file-list="dialogForm.fileList">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              仅支持上传jpg、png、pdf、doc、xlsx、xls、docx、csv文件
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="有效期：">
          <el-radio-group v-model="dialogForm.radio">
            <el-radio :label="1" style="margin-bottom: 20px">无期限</el-radio>
            <el-radio :label="2">设置有效期</el-radio>
          </el-radio-group>
          <el-date-picker v-if="dialogForm.radio == '2'" v-model="dialogForm.date" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" style="margin-left: 20px; width: 85%">
          </el-date-picker>
          <div>系统将在证件过期前30天进行提示</div>
        </el-form-item>
        <el-form-item>
          <el-button v-if="btnFlg" type="primary" @click="submitForm('dialogForm')">保存并添加</el-button>
          <el-button v-else type="info">保存并添加</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog class="preview-dialog" ref="preview-dialog" title="文件预览" :visible.sync="preview" width="1200px" append-to-body>
      <proviewPdf :pdfUrl="pdfUrl"></proviewPdf>
    </el-dialog>
  </div>
</template>

<script>
import { request } from "../../../../network";
import proviewPdf from "../../policy/formalDetails/components/proviewPdf";
import { getMemberId } from "@/assets/public/utils/token";
export default {
  name: "evidence",
  components: {
    proviewPdf,
  },
  data() {
    return {
      formInline: {
        parentLabel: "",
        parentOptions: [],
        sonLabel: "",
        sonOptions: [],
        parentId: "",
        childId: "",
      },
      sonShow: false,
      tabs: [],
      show: false,
      dialogFormVisible: false,
      level_1: "一级选项",
      keyIndex: "0",
      level_2: "二级选项",
      cardList: [],
      preview: false,
      pdfUrl: "",
      main_page: true,
      return_back: "<返回",
      form: {
        file: "",
        suffix: "",
        remarkName: "",
        originalName: "",
        addTime: "",
        value: [],
        options: [],
        radio: "",
        date: "",
        show_input: false,
        update_name: "修改",
        parentId: "",
        childId: "",
        // addTime: '',
        id: "",
      },
      parentId: "",
      childId: "",
      dialogForm: {
        name: "",
        value: "",
        options: [],
        fileList: [],
        radio: "",
        date: "",
        uuid: "",
        childId: undefined,
      },
      total: 0,
      child_id: "",
      btnFlg: true,
      pageSize: 9,
      currentPage: 1,
      itemName: ''
    };
  },
  props: {},
  mounted() {
    this.getClassification();
  },
  watch: {
    "form.radio"(v) {
      this.form.radio = v;
    },
    dialogFormVisible: {
      handler(val) {
        if (!val) {
          setTimeout(() => {
            this.btnFlg = true;
          }, 1000);
        }
      },
    },
  },
  computed: {
    uploadUrl() {
      return `${process.env.VUE_APP_BASEURL}/pcp/declaremanager/upLoadMaterials`;
    },
  },
  methods: {
    // 获取佐证材料分类信息
    getClassification() {
      request({
        method: "GET",
        url: "/pcp/declaremanager/getClassification",
      })
        .then((res) => {
          if (res.data.code === 200) {
            const arr = res.data.data;
            arr.forEach((v) => {
              v.label = v.title;
              v.value = v.id;
              v.children = v.childList;
              v.children.forEach((e) => {
                e.label = e.title;
                e.value = e.id;
              });
            });
            const allArr = [
              {
                label: "全部",
                title: "全部",
                id: "",
                childList: [{ title: "全部", id: "" }],
                children: [{ title: "全部", id: "" }],
              },
            ];
            this.formInline.parentOptions = [...allArr, ...arr];

            this.dialogForm.options = arr;
            this.form.options = arr;
            this.getSupportingMaterials(
              this.formInline.parentId,
              this.formInline.childId,
              1,
              9
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 根据分类获取佐证材料列表
    getSupportingMaterials(parentId, childId, pageNo, pageSize) {
      // var that = this
      const str = {
        memberId: getMemberId(),
        parentId: parentId,
        childId: childId,
        pageNo: pageNo,
        pageSize: pageSize,
      };
      request({
        method: "GET",
        url: "/pcp/declaremanager/getSupportingMaterials",
        params: str,
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.cardList = res.data.data.resultList;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleMaterial() {
      //  this.dialogFormVisible = true;
      //  return
      if (!this.formInline.parentLabel || this.formInline.sonLabel) {
        this.dialogFormVisible = true;
      } else {
        this.$message.error("请选择子标签");
      }
    },
    handleParentLabel(v) {
      this.formInline.parentId = v;
      this.formInline.sonLabel = undefined;
      this.formInline.childId = "";
      for (
        let index = 0;
        index < this.formInline.parentOptions.length;
        index++
      ) {
        if (this.formInline.parentOptions[index].id === v) {
          this.sonShow = true;
          this.formInline.sonOptions =
            this.formInline.parentOptions[index].childList;
        }
      }
    },
    handleSonLabel(v) {
      this.formInline.childId = v;
      this.dialogForm.childId = v;
      //  this.getSupportingMaterials(this.formInline.parentLabel,v,1,9);
    },
    // 查询
    handleSearch() {
      this.currentPage = 1,
        this.getSupportingMaterials(
          this.formInline.parentId,
          this.formInline.childId,
          this.currentPage,
          9
        );
    },
    handleItem(v, i) {
      this.level_2 = "子选项";
      this.level_1 = v.title;
      this.keyIndex = i;
    },
    handleSonItem(v) {
      this.level_2 = v.title;
    },
    // 下载
    download(item) {
      request({
        method: "GET",
        url: "/pcp/declaremanager/downLoadMaterials",
        params: {
          id: item.id,
        },
        responseType: "blob",
      })
        .then((res) => {
          if (res.status === 200) {
            const file = item.remarkName + "." + item.suffix;
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = url;
            link.download = file;
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
            this.$message.success("下载成功");
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 预览
    previewPDF(item) {
      this.preview = !this.preview;
      this.pdfUrl = item.file;
    },
    handleReturn() {
      this.main_page = !this.main_page;
    },
    // 编辑
    edit(item) {
      this.form.update_name = "修改";
      this.form.show_input = false;
      this.main_page = !this.main_page;
      this.form.file = item.file;
      this.form.suffix = item.suffix;
      this.form.addTime = item.addTime;
      this.form.originalName = item.originalName;
      this.form.remarkName = item.remarkName; 
      this.itemName = item.remarkName; 
      this.form.id = item.id;
      this.form.value = [item.parentId, item.childId];
      this.form.radio = item.effectiveDate === "无期限" ? 1 : 0;
      if (item.timeFrame !== "1") this.form.date = item.timeFrame;
    },
    // 删除
    remove(item) {
      request({
        method: "GET",
        url: "/pcp/declaremanager/deleteSupportingMaterials",
        params: {
          id: item.id,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              message: res.data.data,
              type: "success",
            });
            this.getClassification();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleUpdate() {
      this.form.show_input = true;
      this.form.update_name = "确认";
    },
    handleSure() {
      this.form.show_input = false;
      this.itemName = this.form.remarkName;
      this.form.update_name = "修改";
    },
    handleCancle() {
      this.form.show_input = false;
      this.form.remarkName = this.itemName;
      this.form.update_name = "修改";
    },

    // 单选框
    handleRadio(value) { },
    // 编辑
    handleSave() {
      const effectiveDate =
        this.form.radio === 1 ? 1 : this.form.date[0] + "~" + this.form.date[1];

      if (effectiveDate) {
        console.log(effectiveDate);
        if (effectiveDate !== "undefined~undefined") {
          request({
            method: "POST",
            url: "/pcp/declaremanager/updateMaterials",
            data: {
              id: this.form.id,
              typeId: this.form.value[1],
              remarkName: this.form.remarkName,
              effectiveDate: effectiveDate,
            },
          })
            .then((res) => {
              if (res.data.code === 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.main_page = true;
                this.getSupportingMaterials(
                  this.formInline.parentLabel,
                  this.formInline.sonLabel,
                  1,
                  9
                );
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          this.$message.error("请选中证件有效期或日期");
        }
      }
    },

    // 上传
    handleChange(value) { },
    handleSuccess(response, file, fileList) {
      if (response.code === 200) {
        this.dialogForm.uuid = response.data;
      } else {
        fileList.splice(0, 1);
        this.$message.error('文件上传失败，请检查文件名是否过长！');
      }
    },
    handleExceed(files, fileList) {
      this.$message({
        message: "最多上传一个文件",
        type: "warning",
      });
    },
    beforeAvatarUpload(file) {
      const fileSuffix = file.name.substring(file.name.lastIndexOf(".") + 1);
      const whiteList = [
        "jpg",
        "png",
        "pdf",
        "JPG",
        "PNG",
        "PDF",
        "doc",
        "xlsx",
        "DOC",
        "XLSX",
        "xls",
        "XLS",
        "csv",
        "CSV",
        "docx",
        "DOCX",
      ];
      const isLt10M = Number(file.size / 1024 / 1024);
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error(
          "上传文件只能是 jpg、png、pdf、doc、xlsx、xls、csv、docx格式"
        );
        return false;
      }
      if (isLt10M > 10) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
        return false;
      }
      // return whiteList && isLt10M;
    },
    submitForm (formName) {
      const { date, value, name, radio, uuid } = this.dialogForm;
      const effectiveDate =
        radio === 1
          ? 1
          : date[0] + "~" + date[1];
      const childId =
        value.length > 0
          ? value[1]
          : this.formInline.childId;
      const that = this;
      if (!this.formInline.parentId || !this.formInline.childId) {
        if (!name) {
          this.$message.error("请输入材料名称");
        } else if (!uuid) {
          console.log(this.dialogForm);
          this.$message.error("请上传文件");
        } else if (!childId) {
          this.$message.error("请选择分类");
        } else if (!radio) {
          this.$message.error("请选择有效期");
        } else if (radio === 2 && !date) {
          this.$message.error("请选择有效期日期范围");
        } else {
          if(date && this.applicationTime(date[1]) < 30){
            this.$message.error("您的证件有效期不足30天，请及时更新");
          }
          that.btnFlg = false;
          request({
            method: "POST",
            url: "/pcp/declaremanager/addSupportingMaterials",
            data: {
              childId: childId,
              effectiveDate: effectiveDate,
              remarkName: name,
              uuid: uuid,
              memberId: getMemberId(),
            },
          })
            .then((res) => {
              if (res.data.code === 200) {
                that.$message({
                  message: "新增成功",
                  type: "success",
                });
                that.dialogFormVisible = !that.dialogFormVisible;
                // 保存成功做清空对象处理
                for (const key in that.dialogForm) {
                  if (Array.isArray(that.dialogForm[key])) {
                    that.dialogForm[key] = [];
                  } else {
                    that.dialogForm[key] = "";
                  }
                }
                this.getClassification();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        if (!that.dialogForm.name) {
          this.$message.error("请输入材料名称");
        } else if (!this.dialogForm.uuid) {
          this.$message.error("请上传文件");
        } else if (!this.dialogForm.radio) {
          this.$message.error("请选择有效期");
        } else if (this.dialogForm.radio === 2 && !this.dialogForm.date) {
          this.$message.error("请选择有效期日期范围");
        } else {
          that.btnFlg = false;
          request({
            method: "POST",
            url: "/pcp/declaremanager/addSupportingMaterials",
            data: {
              childId: childId,
              effectiveDate: effectiveDate,
              remarkName: this.dialogForm.name,
              uuid: this.dialogForm.uuid,
              memberId: getMemberId(),
            },
          })
            .then((res) => {
              if (res.data.code === 200) {
                that.$message({
                  message: "新增成功",
                  type: "success",
                });
                that.dialogFormVisible = !that.dialogFormVisible;

                // 保存成功做清空对象处理
                for (const key in that.dialogForm) {
                  if (Array.isArray(that.dialogForm[key])) {
                    that.dialogForm[key] = [];
                  } else {
                    that.dialogForm[key] = "";
                  }
                }
                this.getClassification();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    applicationTime (data) {
      let newData = new Date().getTime() / 1000;
      let endData = new Date(data.replace(new RegExp(/\-/g), '/')).getTime() / 1000;
      let d = parseInt((endData - newData) / 60 / 60 / 24);
      return d;

    },
    handleCurrentChange(val) {
      this.getSupportingMaterials(this.parentId, this.child_id, val, 9);
    },
    catgory(v) { },
  },
};
</script>

<style lang="less" scoped>
.evidence {
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  min-height: 50vh;
  .tab-content {
    font-size: 30px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #000000;
    .tabs-item {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      opacity: 0.8;
      display: flex;
      flex-wrap: wrap;
      div {
        margin-right: 49px;
        margin-bottom: 18px;
      }
    }
  }
  .upload {
    width: 100px;
    height: 36px;
    background: #156ed0;
    border-radius: 5px;
    text-align: center;
    line-height: 36px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
  .card-content {
    margin-top: 20px;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    .card {
      width: 30%;
      background: #f7f8fa;
      border: 1px solid #e6e8ee;
      border-radius: 5px;
      height: 120px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 275px;
      margin-bottom: 15px;
      margin-right: 15px;
      div:nth-child(1) {
        img {
          width: 60px;
          height: 60px;
        }
      }
      div:nth-child(2) {
        .title {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          overflow: hidden; 
          white-space: nowrap;
          text-overflow: ellipsis; 
        }
        .text {
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #000000;
          opacity: 0.6;
          overflow: hidden; 
          white-space: nowrap;
          text-overflow: ellipsis; 
        }
      }
    }
  }
}
.active {
  color: #156ed0;
}
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.preview-dialog {
  /deep/.el-dialog {
    margin: 0 auto !important;
    height: 100vh;
    overflow-y: scroll;
    .el-dialog__header {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 1;
    }
    .el-dialog__body {
      height: calc(100vh - 120px);
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: #cccccc;
    }
  }
}
.upload-file {
  display: flex;
  justify-content: center;
  .click-file {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 420px;
    height: 100px;
    border: 1px dashed #000000;
    opacity: 0.2;
    border-radius: 3px;
    color: #000000;
    .icon {
      margin-right: 15px;
      i {
        font-size: 34px;
      }
    }
    .remark {
      div {
        margin-top: 11px;
      }
    }
  }
}
.submit {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  div {
    width: 160px;
    height: 48px;
    background: #156ed0;
    border-radius: 5px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    text-align: center;
  }
}
.content_edit {
  display: flex;
  margin-top: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  .edit_img {
    margin-right: 50px;
    img {
      width: 158px;
      height: 160px;
    }
  }
  .edit_form {
    > div {
      display: flex;
      margin-bottom: 27px;
      div:nth-child(1) {
        margin-right: 31px;
      }
    }
  }
}
.form_title {
  width: 70px;
  height: 12px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  opacity: 0.6;
}
.remarkName_title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  margin-bottom: 11px;
  word-break: break-all;
  word-wrap: break-word;
  width: 680px;
}
.modify {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #156ed0;
}
.form_select {
  display: flex;
}
.form_tips {
  width: 420px;
  height: 26px;
  background: #eeeeee;
  opacity: 0.5;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 22px;
  display: flex;
  justify-content: center;
}
.form_sub {
  width: 160px;
  height: 48px;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  line-height: 48px;
  background: #156ed0;
  border-radius: 5px;
}
.form_radio_group {
  .el-radio-group {
    display: flex;
    flex-direction: column;
  }
}
.none-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
}
</style>

<style>
.el-tooltip__popper{
  max-width: 20%;
}
.el-tooltip__popper,.el-tooltip__popper.is-dark{
  background:rgb(48, 65, 86) !important;
  color: #fff !important;
  line-height: 24px;
}
</style>