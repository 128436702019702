<template>
  <div class="member">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>申报管家</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row :gutter="100" class="content">
      <el-col :span="4">
        <div class="tabs">
          <div v-for="(item, index) in tabs" :key="index" :class="item.active ? 'active' : ''" @click="handleTabs(index)">
            <img :src="item.active ? item.selected : item.url" alt="" />
            <span :class="item.active?'ft_blue':'ft_black'">{{ item.name }}</span>
          </div>
        </div>
      </el-col>
      <el-col :span="20">
        <div class="pages">
          <project v-show="flag == '0'"></project>
          <declare v-show="flag == '1'"></declare>
          <!-- <service v-show="flag == '2'"  @getServiceID="getServiceID($event)" ref="service"></service> -->
          <!-- <service-details v-show="flag == '2-2' && (vip !== '普通用户' || vip !== '体验会员')" ref="serviceDetails" @openControl="$refs.service.getList()"></service-details> -->
          <evidence v-show="flag == '2'"></evidence>
        </div>
      </el-col>
    </el-row>
    <router-view />
  </div>
</template>

<script>
import project from './components/project.vue';
import declare from './components/declare.vue';
// import service from './components/service.vue'
import evidence from './components/evidence.vue';
// import demand from "./components/demand.vue"
// import serviceDetails from './components/serviceDetails.vue'
import { request } from '../../../network/index';
import { getVip } from '@/assets/public/utils/token';
// import open from './components/components/open.vue'
export default {
  components: {
    project,
    declare,
    // service,
    evidence
    // demand,
    // serviceDetails,
    // open
  },
  data() {
    return {
      tabs: [
        {
          id: 0,
          url: require('../../../assets/pc/images/member/project_unActive.png'),
          selected: require('../../../assets/pc/images/member/project_active.png'),
          name: '项目规划',
          active: true
        },
        {
          id: 1,
          url: require('../../../assets/pc/images/member/declare_unActive.png'),
          selected: require('../../../assets/pc/images/member/declare_active.png'),
          name: '申报计划',
          active: false
        },
        // {
        //   id: 2,
        //   url: require("../../../assets/pc/images/member/service_unActive.png"),
        //   selected: require("../../../assets/pc/images/member/service_active.png"),
        //   name: "服务机构",
        //   active: false,
        // },
        {
          id: 3,
          url: require('../../../assets/pc/images/member/evidence_unActive.png'),
          selected: require('../../../assets/pc/images/member/evidence_active.png'),
          name: '佐证材料',
          active: false
        }
      ],
      flag: '0',
      serviceDetailsID: '',
      vip: ''
    };
  },
  mounted() {
    getVip(request).then(res => {
      this.vip = res;
    });
    if (this.$route.query.keyindex) {
      this.flag = this.$route.query.keyindex;
    }
    if (this.$route.query.flag) {
      this.flag = this.$route.query.flag;
      this.tabs.forEach((item, index) => {
        if (index == this.flag) {
          item.active = true
        } else {
          item.active = false
        }
      })
    }
  },
  methods: {
    // 选中切换
    handleTabs(index) {
      this.flag = index;
      const tabs = this.tabs;
      tabs.forEach((v, i) => { i === index ? v.active = true : v.active = false; });
      this.tabs = tabs;
    },
    getServiceID(event) {
      this.serviceDetailsID = event;
      this.flag = '2-2';
      // this.$refs.serviceDetails.getInfo(this.serviceDetailsID)
    }
  }
};
</script>

<style lang="less" scoped>
.member {
  background-color: #fff;
  padding: 43px 0px 0 0px;
  .content {
    margin: 0 50px;
    margin-top: 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    opacity: 0.9;
    margin-bottom: 100px;
    .tabs {
      div {
        display: flex;
        align-items: center;
        width: 180px;
        height: 50px;
        border-radius: 5px;
        margin-bottom: 23px;
        img {
          width: 18px;
          height: 18px;
          margin-right: 20px;
          margin-left: 19px;
        }
      }
    }
  }
}
.active {
  background: #ebf3ff;
}
.ft_black {
  color: #000000;
  opacity: 0.9;
}
.ft_blue {
  color: #156ed0;
}
</style>
