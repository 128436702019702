import { render, staticRenderFns } from "./evidence.vue?vue&type=template&id=bd9c9264&scoped=true"
import script from "./evidence.vue?vue&type=script&lang=js"
export * from "./evidence.vue?vue&type=script&lang=js"
import style0 from "./evidence.vue?vue&type=style&index=0&id=bd9c9264&prod&lang=less&scoped=true"
import style1 from "./evidence.vue?vue&type=style&index=1&id=bd9c9264&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd9c9264",
  null
  
)

export default component.exports